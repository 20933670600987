<template>
  <vx-card>
    <div class="vx-col sm:w-1/1 w-full mb-base">
      <vs-tabs>
        <vs-tab label="AR AGING MIX Report">
          <div class="tab-text">
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Source</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <multiselect
                  v-model="selectedSource"
                  :options="optionSource"
                  :multiple="false"
                  :allow-empty="false"
                  :group-select="false"
                  :max-height="120"
                  :limit="3"
                  placeholder="Type to search"
                >
                </multiselect>
              </div>
            </div>
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Operating Unit / Territory</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <multiselect
                  class="selectExample"
                  v-model="operatingUnit"
                  :options="optionOU"
                  :multiple="false"
                  :allow-empty="false"
                  :group-select="false"
                  :max-height="120"
                  :limit="3"
                  placeholder="Type to search"
                  :custom-label="customLabel"
                >
                </multiselect>
              </div>
            </div>
            <!--  <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Doc Date</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full whitespace-no-wrap">
                <div class="mt-4 flex justify-between">
                  <date-range-picker
                    style="min-height: 40px"
                    class="w-full"
                    ref="picker"
                    opens="center"
                    :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
                    :singleDatePicker="false"
                    :timePicker="false"
                    :timePicker24Hour="false"
                    :showWeekNumbers="false"
                    :showDropdowns="false"
                    :autoApply="true"
                    v-model="docDate"
                    :linkedCalendars="true"
                  >
                    <template v-slot:input="picker" style="min-width: 350px">
                      {{ dateFormat(picker.startDate) }} -
                      {{ dateFormat(picker.endDate) }}
                    </template>
                  </date-range-picker>
                  <vs-button
                    class="ml-4"
                    color="danger"
                    icon-pack="feather"
                    icon="icon-x"
                    @click="
                      () => {
                        this.docDate.startDate = null;
                        this.docDate.endDate = null;
                      }
                    "
                  ></vs-button>
                </div>
              </div>
            </div>-->
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Posting Date</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full whitespace-no-wrap">
                <div class="mt-4 flex justify-between">
                  <date-range-picker
                    style="min-height: 40px"
                    class="w-full"
                    ref="picker"
                    opens="center"
                    :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
                    :singleDatePicker="false"
                    :timePicker="false"
                    :timePicker24Hour="false"
                    :showWeekNumbers="false"
                    :showDropdowns="false"
                    :autoApply="true"
                    v-model="postingDate"
                    :linkedCalendars="true"
                  >
                    <template v-slot:input="picker" style="min-width: 350px">
                      {{ dateFormat(picker.startDate) }} -
                      {{ dateFormat(picker.endDate) }}
                    </template>
                  </date-range-picker>
                  <vs-button
                    class="ml-4"
                    color="danger"
                    icon-pack="feather"
                    icon="icon-x"
                    @click="
                      () => {
                        this.postingDate.startDate = null;
                        this.postingDate.endDate = null;
                      }
                    "
                  ></vs-button>
                  <!-- <template>
              <vx-tooltip text="reset">
                <vs-button
                  type="line"
                  icon-pack="feather"
                  icon="icon-refresh-cw"
                  @click.stop="handleResetInvoiceDateFilter()"
                />
              </vx-tooltip>
            </template> -->
                </div>
              </div>
            </div>
            <!-- <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Due Date</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full whitespace-no-wrap">
                <div class="mt-4 flex justify-between">
                  <date-range-picker
                    style="min-height: 40px"
                    class="w-full"
                    ref="picker"
                    opens="center"
                    :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
                    :singleDatePicker="false"
                    :timePicker="false"
                    :timePicker24Hour="false"
                    :showWeekNumbers="false"
                    :showDropdowns="false"
                    :autoApply="true"
                    v-model="dueDate"
                    :linkedCalendars="true"
                  >
                    <template v-slot:input="picker" style="min-width: 350px">
                      {{ dateFormat(picker.startDate) }} -
                      {{ dateFormat(picker.endDate) }}
                    </template>
                  </date-range-picker>
                  <vs-button
                    class="ml-4"
                    color="danger"
                    icon-pack="feather"
                    icon="icon-x"
                    @click="
                      () => {
                        this.dueDate.startDate = null;
                        this.dueDate.endDate = null;
                      }
                    "
                  ></vs-button>
                  <template>
                    <vx-tooltip text="reset">
                      <vs-button
                        type="line"
                        icon-pack="feather"
                        icon="icon-refresh-cw"
                        @click.stop="handleResetInvoiceDateFilter()"
                      />
                    </vx-tooltip>
                  </template>
                </div>
              </div>
            </div> -->
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full">
                <span>Open Key Date</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <datepicker :inline="false" v-model="openKeyDate"></datepicker>
              </div>
            </div>
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Status Balance</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <v-select
                  :options="['All', 'Open', 'Close']"
                  v-model="selectedStatusBalance"
                ></v-select>
              </div>
            </div>
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center"></div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-button
                  color="success"
                  @click="handleGenerateExcelTerritory()"
                  >Generate Excel</vs-button
                >
              </div>
            </div>
            <div style="height: 150px;"></div>
            <!-- <div class="vx-row">
              <DataTable
                :baseUrl="this.baseUrl"
                :dueDate="this.dueDate"
                :postingDate="this.postingDate"
                :docDate="this.docDate"
                :operatingUnit="this.operatingUnit"
                :openKeyDate="this.openKeyDate"
                :statusBalance="this.selectedStatusBalance"
                :source="this.selectedSource"
                :sources="this.sources"
                :draw="this.draw"
              ></DataTable>
            </div> -->
          </div>
        </vs-tab>
        <vs-tab label="Export">
          <div class="tab-text">
            <data-table-export></data-table-export>
          </div>
        </vs-tab>
      </vs-tabs>
    </div>
  </vx-card>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import moment from "moment";
//you need to import the CSS manually
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import DataTable from "./DataTable.vue";
import DataTableExport from "./DataTableExport.vue";

import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";

export default {
  components: {
    DateRangePicker,
    DataTable,
    DataTableExport,
    Datepicker,
    vSelect
  },
  mounted() {
    this.getOptionOU();
    // this.getOptionCustomerCategory();
    // this.getOptionSalesPersonal();
    const today = new Date();

    const theDay = {
      startDate: new Date(
        today.getFullYear(),
        today.getMonth() - 1,
        today.getDate()
      ),
      endDate: new Date(
        today.getFullYear(),
        today.getMonth() - 1,
        today.getDate()
      )
    };

    // this.docDate = theDay;
    this.postingDate = theDay;
    // this.dueDate = theDay;
  },
  data: () => ({
    baseUrl: "/api/v1/report/ar-aging-mix",
    data: [],
    tabColor: [
      "success",
      "primary",
      "warning",
      "info",
      "success",
      "rgb(62, 201, 214)"
    ],
    tabIndex: 0,
    salesChannel: {},
    customerCategory: {},
    optionCustomerCategory: [{}],
    salesPersonal: {},
    optionSalesPersonal: [{}],
    colorx: "success",
    selectedSO: {},
    selectedStatusBalance: "Open",
    // operatingUnit: {
    //   Territory: {
    //     name: "",
    //     code: "",
    //     ID: -1
    //   }
    // },
    operatingUnit: [],
    defaultDate: {
      startDate: null,
      endDate: null
    },
    docDate: {
      startDate: null,
      endDate: null
    },
    postingDate: {
      startDate: null,
      endDate: null
    },
    dueDate: {
      startDate: null,
      endDate: null
    },
    responseData: {},
    // optionOU: [
    //   {
    //     Territory: {
    //       code: "",
    //       name: ""
    //     }
    //   }
    // ],
    optionOU: [],
    optionSource: ["MIX"],
    selectedSource: "MIX",
    sources: ["MIX"],
    openKeyDate: new Date(),
    draw: 0
  }),
  watch: {
    operatingUnit(newVal, OldVal) {
      console.log(newVal.Territory.ID + "  " + OldVal.Territory.ID);
    },
    selectedSource() {
      this.getOptionOU();
    }
  },
  methods: {
    customLabel(val) {
      if (val) {
        return val.Territory.code === "-"
          ? `${val.Territory.name}`
          : `(${val.Territory.code}) ${val.Territory.name}`;
      }
    },
    handleResetInvoiceDateFilter() {
      this.filter_date.startDate = null;
      this.filter_date.endDate = null;
    },
    dateFormat(date) {
      let a = null;
      if (date != null) {
        a = moment(date).format("MMM DD, YYYY");
      }
      return a;
    },
    getOptionOU() {
      var url = "/api/v1/master/territory-master";

      let params = { sources: this.sources };
      if (this.selectedSource !== "All") {
        params = Object.assign(params, { source: this.selectedSource });
      }

      this.$http
        .get(url, {
          params: params
        })
        .then(resp => {
          // this.optionOU = [
          //   {
          //     ID: 0,
          //     id: 0,
          //     OperatingUnitID: 0,
          //     TerritoryID: 0,
          //     Territory: {
          //       ID: 0,
          //       territory_area_id: 0,
          //       code: "-",
          //       name: "All"
          //     }
          //   }
          // ];
          for (let i = 0; i < resp.data.records.length; i++) {
            resp.data.records[i]["ID"] = resp.data.records[i].id;
            resp.data.records[i]["OperatingUnitID"] = resp.data.records[i].id;
            resp.data.records[i]["TerritoryID"] = resp.data.records[i].id;
            resp.data.records[i]["Territory"] = {
              ID: resp.data.records[i].id,
              territory_area_id: resp.data.records[i].territory_area_id,
              code: resp.data.records[i].code,
              name: resp.data.records[i].name
            };
          }

          this.optionOU = [...this.optionOU, ...resp.data.records];
          if (resp.data.records.length > 1) {
            this.operatingUnit = this.optionOU[0];
          }
        });
    },
    // getOptionCustomerCategory() {
    //   this.$http.get("/api/v1/master/customer-category-all").then((resp) => {
    //     if (resp.code == 200) {
    //       this.optionCustomerCategory = [];
    //       this.optionCustomerCategory.push({ name: "All" });
    //       for (
    //         var customerCategory = 0;
    //         customerCategory < resp.data.length;
    //         customerCategory++
    //       ) {
    //         this.optionCustomerCategory.push(resp.data[customerCategory]);
    //       }
    //       this.customerCategory = this.optionCustomerCategory[0];
    //     } else {
    //       // this.$vs.loading.close();
    //       this.$router.push("/");
    //     }
    //   });
    // },
    // getOptionSalesPersonal() {
    //   this.$http.get("/api/v1/sales-order/sales-personal").then((resp) => {
    //     if (resp.code == 200) {
    //       this.optionSalesPersonal = [];
    //       this.optionSalesPersonal.push({ id: 0, name: "All" });
    //       let data = resp.data.SalesPersonalSO;
    //       for (var idx = 0; idx < data.length; idx++) {
    //         this.optionSalesPersonal.push(data[idx]);
    //       }
    //       this.salesPersonal = this.optionSalesPersonal[0];
    //     } else {
    //       // this.$vs.loading.close();
    //       // this.$router.push("/");
    //     }
    //   });
    // },
    redirectTab(index) {
      this.$refs.tabs.activeChild(index);
    },
    handleExport(file) {
      var fileTitle =
        "ARAGING_MIX_REPORT_" +
        "_" +
        this.selectedSource +
        "_" +
        this.operatingUnit.Territory.code +
        "_" +
        this.operatingUnit.Territory.name.replace(" ", "_") +
        "_" +
        moment(this.filter_date.startDate).format("YYYY-MM-DD") +
        "_-_" +
        moment(this.filter_date.endDate).format("YYYY-MM-DD");

      this.$http
        .get(
          this.baseUrl +
            (this.operatingUnit.Territory.code !== "-"
              ? "/exportv2"
              : "/export/all/territory"),
          {
            params: {
              source: this.selectedSource,
              sources: this.sources,
              territory_id: this.operatingUnit.Territory.ID,
              start_date: moment(this.filter_date.startDate).format(
                "YYYY-MM-DD"
              ),
              end_date: moment(this.filter_date.endDate).format("YYYY-MM-DD"),
              file: file
            },
            responseType: "arraybuffer",
            headers: {
              Accept: "application/octet-stream"
            }
          }
        )
        .then(resp => {
          if (resp.status === "error") {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          } else {
            var fileURL = window.URL.createObjectURL(new Blob([resp]));
            var fileLink = document.createElement("a");
            fileLink.href = fileURL;

            if (file === "excel") {
              fileLink.setAttribute("download", fileTitle + ".xlsx");
            } else {
              fileLink.setAttribute("download", fileTitle + ".pdf");
            }
            document.body.appendChild(fileLink);
            fileLink.click();
          }
        });
    },
    handleDrawTable() {
      this.draw++;
    },

    handleGenerateExcelAllTerritory() {
      this.$vs.loading();
      var fileTitle = "ARAGING_MIX_REPORT_" + this.selectedSource + "_ALL_";

      if (this.startDocDate || this.endDocDate) {
        fileTitle +=
          "DOC_DATE_" +
          moment(this.startDocDate).format("YYYY-MM-DD") +
          "_-_" +
          moment(this.endDocDate).format("YYYY-MM-DD") +
          "_";
      }

      if (this.startPostingDate || this.endPostingDate) {
        "POSTING_DATE_" +
          moment(this.startPostingDate).format("YYYY-MM-DD") +
          "_-_" +
          moment(this.endPostingDate).format("YYYY-MM-DD") +
          "_";
      }

      if (this.startDueDate || this.endDueDate) {
        "DUE_DATE_" +
          moment(this.startDueDate).format("YYYY-MM-DD") +
          "_-_" +
          moment(this.endDueDate).format("YYYY-MM-DD") +
          "_";
      }

      fileTitle +=
        "OPEN_KEY_DATE_" + moment(this.openKeyDate).format("YYYY-MM-DD") + "_";

      fileTitle += "STATUS_BALANCE_" + this.selectedStatusBalance;

      this.$http
        .get(
          this.baseUrl +
            (this.operatingUnit.Territory.code !== "-"
              ? "/exportv2"
              : "/export/all/territory"),
          {
            params: {
              source: this.selectedSource,
              sources: this.sources,
              territory_id: 0,
              file: "excel",
              start_due_date: this.dueDate.startDate
                ? moment(this.dueDate.startDate).format("YYYY-MM-DD")
                : null,
              end_due_date: this.dueDate.endDate
                ? moment(this.dueDate.endDate).format("YYYY-MM-DD")
                : null,
              start_doc_date: this.docDate.startDate
                ? moment(this.docDate.startDate).format("YYYY-MM-DD")
                : null,
              end_doc_date: this.docDate.endDate
                ? moment(this.docDate.endDate).format("YYYY-MM-DD")
                : null,
              start_posting_date: this.postingDate.startDate
                ? moment(this.postingDate.startDate).format("YYYY-MM-DD")
                : null,
              end_posting_date: this.postingDate.endDate
                ? moment(this.postingDate.endDate).format("YYYY-MM-DD")
                : null,
              open_key_date: moment(this.openKeyDate).format("YYYY-MM-DD"),
              status_balance: this.selectedStatusBalance,
              title: fileTitle
            }
          }
        )
        .then(resp => {
          this.$vs.loading.close();
          if (resp.status === "error") {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              color: "success",
              title: "Processing",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          }
        });
    },

    handleGenerateExcelTerritory() {
      this.$vs.loading();
      var fileTitle =
        "ARAGING_MIX_REPORT_" +
        "SOURCE_" +
        this.selectedSource +
        "_" +
        this.operatingUnit.Territory.code +
        "_" +
        this.operatingUnit.Territory.name.replace(" ", "_") +
        "_";

      if (this.postingDate.startDate || this.postingDate.endDate) {
        fileTitle +=
          "POSTING_DATE_" +
          moment(this.postingDate.startDate).format("YYYY-MM-DD") +
          "_-_" +
          moment(this.postingDate.endDate).format("YYYY-MM-DD") +
          "_";
      } else {
        fileTitle += "POSTING_DATE_ALL_";
      }

      fileTitle +=
        "OPEN_KEY_DATE_" + moment(this.openKeyDate).format("YYYY-MM-DD") + "_";

      if (!this.selectedStatusBalance) {
        this.selectedStatusBalance = "All";
      }

      fileTitle += "STATUS_BALANCE_" + this.selectedStatusBalance;
      this.$http
        .get(this.baseUrl + "/export/territory", {
          params: {
            title: fileTitle,
            status_balance: this.selectedStatusBalance,
            source: this.selectedSource,
            sources: this.sources,
            territory_id: this.operatingUnit.Territory.ID,
            // start_due_date: this.dueDate.startDate
            //   ? moment(this.dueDate.startDate).format("YYYY-MM-DD")
            //   : null,
            // end_due_date: this.dueDate.endDate
            //   ? moment(this.dueDate.endDate).format("YYYY-MM-DD")
            //   : null,
            // start_doc_date: this.docDate.startDate
            //   ? moment(this.docDate.startDate).format("YYYY-MM-DD")
            //   : null,
            // end_doc_date: this.docDate.endDate
            //   ? moment(this.docDate.endDate).format("YYYY-MM-DD")
            //   : null,
            start_posting_date: this.postingDate.startDate
              ? moment(this.postingDate.startDate).format("YYYY-MM-DD")
              : null,
            end_posting_date: this.postingDate.endDate
              ? moment(this.postingDate.endDate).format("YYYY-MM-DD")
              : null,
            open_key_date: moment(this.openKeyDate).format("YYYY-MM-DD")
          }
        })
        .then(resp => {
          if (resp.status == "success") {
            this.$vs.notify({
              color: "success",
              title: "Processing",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
            if (resp.status == "error") {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle"
              });
            }
          }
        });
    }
  }
};
</script>

<style scoped>
.vs-collapse-item--content {
  height: 100%;
  max-height: 14444px;
}
</style>
